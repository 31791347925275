import { createRouter, createWebHistory } from 'vue-router';
import store from '@/home/store/index';
import api from '@/home/api';
import LoginView from '@/home/views/Login.vue';
import HomeView from '@/home/views/Home.vue';

const routes = [
    {
        path: '/login',
        name: 'LoginView',
        component: LoginView
    },
    {
        path: '/',
        name: 'HomeView',
        alias: '/home',
        component: HomeView,
        meta: {
            authRequire: true,
            area: 'home'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'HomeView',
        component: HomeView,
        meta: {
            authRequire: true,
            area: 'home'
        }
    },
    // Lazy-load route example
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from) => {
    if (to.name !== 'LoginView') {
        const canAccess = await canUserAccess(to);
        if (!canAccess) {
            return router.push({ name: 'LoginView' });
        }
    }
});

async function canUserAccess(target) {
    let canAccess = false;
    if (target.meta.authRequire) {
        try {
            await api.canUserAccess(target.meta.area);
            canAccess = true;
        } catch (e) {
            canAccess = false;
        }
    } else {
        canAccess = true;
    }
    return canAccess;
}

export default router
