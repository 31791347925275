<template>
    <div class="auth">
        <div class="container">
            <div class="card">
                <div class="card-body">
                    <input-container
                            v-model="name"
                            :label="'Логин'"
                            :error="nameError"/>
                    <input-container
                            v-model="password"
                            :label="'Пароль'"
                            :isSecure="true"
                            :error="passwordError"/>
                    <div class="action">
                        <div class="form-error-message" v-if="formError">{{ formError }}</div>
                        <btn-container :primary="true" :small="true" :title="'Войти'" @click.stop="proceed"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import BtnContainer from '@/common/components/BtnContainer.vue';
import InputContainer from '@/common/components/InputContainer.vue';

export default {
    name: 'Login',
    components: {
        BtnContainer,
        InputContainer
    },
    data() {
        return {
            nameError: null,
            passwordError: null,
            formError: null
        }
    },
    computed: {
        name: {
            get() {
                return this.$store.state.login;
            },
            set(login) {
                this.$store.commit('setLogin', login);
            }
        },
        password: {
            get() {
                return this.$store.state.password;
            },
            set(password) {
                this.$store.commit('setPassword', password);
            }
        },
        authState: function () {
            return this.name && this.password;
        }
    },
    methods: {
        ...mapActions([
            'login'
        ]),
        proceed() {
            this.formError = null;
            const REQUIRED_FIELD_ERROR = 'Поле не должно быть пустым';

            if (!this.name || !(this.name.trim())) {
                this.nameError = REQUIRED_FIELD_ERROR;
                return;
            } else {
                this.nameError = null;
            }

            if (!this.password || !(this.password.trim())) {
                this.passwordError = REQUIRED_FIELD_ERROR;
                return;
            } else {
                this.passwordError = null;
            }

            if (this.authState) this.login()
                .then(r => {
                    console.log('login success');
                    this.$router.push({ name: 'HomeView' });
                })
                .catch(e => {
                    console.error(e);
                    if (e.response) {
                        this.formError = e.response.data.reason;
                    } else {
                        this.formError = e.message;
                    }
                });
        }
    }
}
</script>

<style lang="sass" scoped>
@import '../../common/assets/index.sass'

.auth
    display: flex
    flex-direction: column
    justify-content: center
    width: 100vw
    height: 100vh
    .container
        display: flex
        justify-content: center
        .card
            padding: 40px 40px 20px
            width: 300px
            border-radius: 8px
            .card-body
                .input-container:first-child
                    margin-bottom: 10px
                .input-container:nth-child(2)
                    margin-bottom: 22px
                .action
                    text-align: center
                    .btn-container
                        width: 150px

.form-error-message
    margin: 4px 0
    color: $brand-primary-color

</style>
