import { createStore } from 'vuex';
import globalStore from '../../common/store';
import api from '../api';

export default createStore({
    state: {
        login: process.env.NODE_ENV === 'development' ? 'shura.ru.1987@gmail.com' : null,
        password: process.env.NODE_ENV === 'development' ? '123' : null
    },
    mutations: {
        setLogin: ( state, login ) => state.login = login,
        setPassword: ( state, password ) => state.password = password,
    },
    getters: {

    },
    actions: {
        login({ state }) {
            return api.login(state.login, state.password);
        }
    },
    modules: {
        globalStore
    }
})
