<template>
    <div class="layout home-layout">
        <div v-if="authUser && accessAreas" class="container">
            <template v-for="(area, i) in accessAreas" :key="i">
                <div v-if="authUser.accessAreaIds.includes(area.id)" class="card" @click="navigateTo(area.code)">
                    <div class="card-body">
                        <div class="title">{{ area.alias }}</div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Home',
    components: {

    },
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters([
            'authUser',
            'accessAreas',
        ])
    },
    watch: {
        authUser() {
            this.autoProceed();
        },
        accessAreas() {
           this.autoProceed();
        }
    },
    methods: {
        navigateTo(to) {
            location.assign('/' + to);
        },
        autoProceed() {
            if (!this.authUser) return;
            if (!this.accessAreas) return;
            if (this.authUser.accessAreaIds.length === 1) {
                const proceed = this.accessAreas.filter(area => area.id === this.authUser.accessAreaIds[0]);
                this.navigateTo(proceed[0].code);
            }
        }
    },
    created() {
        this.$store.dispatch('defineUser');
        this.$store.dispatch('getAccessAreas');
    }
}
</script>

<style lang="sass" scoped>
@import '../../common/assets/index.sass'

.home-layout
    display: flex
    flex-direction: column
    justify-content: center
    width: 100vw
    height: 100vh
    .container
        display: flex
        justify-content: center
        .card
            width: 120px
            height: 120px
            padding: 20px
            color: $gray-700-color
            border-radius: 10px
            background-color: $gray-50-color
            cursor: pointer
            -webkit-user-select: none
            -moz-user-select: none
            -ms-user-select: none
            user-select: none
            .card-body
                display: flex
                flex-direction: column
                justify-content: center
                width: 100%
                height: 100%
                .title
                    color: inherit
                    font-size: 18px
                    text-align: center
        .card:not(:last-child)
            margin-right: 20px
        .card:hover
            color: #fff
            background-color: $brand-primary-color
            border: 1px solid $brand-primary-color

</style>
